/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";


.button-center {
  margin: 0 auto;
  display: block;
}


.stupidDocumentationOfThatFuckedSurveyJS {
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  border-radius: 0.25rem !important;
  line-height: 1.5 !important;
  float: right;
  border: 1px solid transparent !important;
  font-weight: 400 !important;
}

.active {
  color: #0D6EFD;
}

.bordered {
  border: 1px solid #CED4DA;
}

.green-button {
  background-color: #229D64 !important;
  border-color: #229D64 !important;
  color: #ffffff !important;
  width: 170px;
  padding: 0.375rem 0 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  border: 1px solid transparent !important;
  border-radius: 0.25rem !important;
  margin-top: 24px !important;
}

.display-none{
  display: none;
}

span {
  &.huge {
    font-size: 24px;
  }
}

.swal2-actions{
  button{
    width: 140px;
  }
}

img{
  &.logo{
    height: 42px;
  }
}


@media (max-width: 576px) {
  .swal2-actions{
    button{
      width: 100%;
    }
  }
}

.my {
  &-1 {
    margin: 8px 0 !important;
  }

  &-2 {
    margin: 16px 0 !important;
  }

  &-3 {
    margin: 24px 0 !important;
  }

  &-4 {
    margin: 32px 0 !important;
  }

  &-5 {
    margin: 40px 0 !important;
  }
}

.mx {
  &-1 {
    margin: 0 8px !important;
  }

  &-2 {
    margin: 0 16px !important;
  }

  &-3 {
    margin: 0 24px !important;
  }

  &-4 {
    margin: 0 32px !important;
  }

  &-5 {
    margin: 0 40px !important;
  }
}

.mt {
  &-1 {
    margin-top: 8px !important;
  }

  &-2 {
    margin-top: 16px !important;
  }

  &-3 {
    margin-top: 24px !important;
  }

  &-4 {
    margin-top: 32px !important;
  }

  &-5 {
    margin-top: 40px !important;
  }
}

.mb {
  &-1 {
    margin-bottom: 8px !important;
  }

  &-2 {
    margin-bottom: 16px !important;
  }

  &-3 {
    margin-bottom: 24px !important;
  }

  &-4 {
    margin-bottom: 32px !important;
  }

  &-5 {
    margin-bottom: 40px !important;
  }
}

.ml {
  &-1 {
    margin-left: 8px !important;
  }

  &-2 {
    margin-left: 16px !important;
  }

  &-3 {
    margin-left: 24px !important;
  }

  &-4 {
    margin-left: 32px !important;
  }

  &-5 {
    margin-left: 40px !important;
  }
}

.mr {
  &-1 {
    margin-right: 8px !important;
  }

  &-2 {
    margin-right: 16px !important;
  }

  &-3 {
    margin-right: 24px !important;
  }

  &-4 {
    margin-right: 32px !important;
  }

  &-5 {
    margin-right: 40px !important;
  }
}


.p-1 {
  padding: 8px !important;
}

.px-1 {
  padding: 0 8px !important;
}

.p-2 {
  padding: 16px !important;
}